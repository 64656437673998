import { FC, memo, useRef, useState } from 'react';
import { useStyle } from 'src/utils/theme/useStyle';
import { ModalSettingSliderRules } from './ModalSettingSlider.style';
import Modal, { ModalNames } from 'src/components/Modal/Modal';
import Text from 'src/components/UI/Text/Text';
import { ImageLoader } from 'src/components/UI/ImageLoader/ImageLoader';
import { Input } from 'src/components/UI/Input/Input';
import { useTheme } from 'src/utils/theme/useTheme';
import { Button } from 'src/components/UI/Button/Button';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useScrollModal } from 'src/hooks/useScrollModal';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { SliderState, removeSlide, updateSlider } from 'src/redux/slices/sliderSlice';
import { ISlide, ISlider } from 'src/types/slider';
import { sliderUpsert } from 'src/redux/api/slider/sliderUpsert';
import { v4 } from 'uuid';
import { NetworkStatus } from 'src/utils/network/network.constant';
import { useTranslation } from 'src/utils/i18n/hooks/useTranslation';
import { useValidateUrl } from 'src/utils/hooks/useValidateUrl';
import { Trans } from 'src/utils/i18n/components/trans';
import { linkMaxLength } from 'src/utils/constants';
import { correctUrlOnPaste, trimAndReplaceFunction } from 'src/utils/validateUrl';

interface IProps {
  active: boolean;
  onClose: () => void;
  slide?: ISlide;
  editingSlide?: boolean;
}

interface IFormInput {
  clickUrl: string;
}

const minimumResolution = '1033×1033';

export const ModalSettingSlider: FC<IProps> = memo(function ModalSettingSlider(props) {
  const { active, editingSlide, slide, onClose } = props;
  // const [slideImage, setSlideImage] = useState<File | null>(null);
  const modalHeaderRef = useRef<HTMLDivElement>(null);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const modalFooterRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { slider, networkStatus } = useAppSelector(SliderState);
  const { css } = useStyle(ModalSettingSliderRules, {
    headerHeight: modalHeaderRef.current?.clientHeight,
    footerHeight: modalFooterRef.current?.clientHeight,
  });
  const { t, tPlural } = useTranslation();
  const validateUrl = useValidateUrl();
  const { theme } = useTheme();
  const { colorGrey, colorBlack } = theme;
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      clickUrl: slide?.clickUrl,
    },
  });

  useScrollModal(modalInnerRef);

  const [image, setImage] = useState<{ file: null | File; url: string }>({
    file: null,
    url: slide?.imageUrl,
  });

  const onChangeFile = (file: File) => {
    const url = URL.createObjectURL(file);
    setImage({ file: file, url: url });
  };

  const onSubmit: SubmitHandler<IFormInput> = data => {
    const { clickUrl } = data;

    if (editingSlide) {
      const updatedSlide: ISlide = {
        clickUrl,
        imageUrl: image.url || slide?.imageUrl,
        id: slide.id,
      };
      updateSlide(updatedSlide);
    } else {
      const newSlide: ISlide = { clickUrl, imageUrl: image.url || slide?.imageUrl, id: v4() };
      createSlide(newSlide);
    }
  };

  function clickRemoveButton() {
    const updatedSlider: ISlider = {
      ...slider,
      items: [...slider.items].filter(item => item.id !== slide.id),
    };
    dispatch(sliderUpsert({ slider: updatedSlider })).then(() => {
      dispatch(removeSlide(slide.id));
      onClose();
    });
  }

  function createSlide(slide: ISlide) {
    const updatedSlider: ISlider = {
      ...slider,
      items: [...slider.items, slide],
    };
    dispatch(
      sliderUpsert({
        slider: updatedSlider,
        slideUpdateData: { slideId: slide.id, file: image.file },
      }),
    )
      .unwrap()
      .then(res => {
        dispatch(
          updateSlider({
            ...slider,
            items: [
              ...slider.items,
              {
                ...slide,
                imageUrl: res.imgUrl,
              },
            ],
          } as ISlider),
        );
        onClose();
      })
      .catch(e => console.error(e));
  }

  function updateSlide(slide: ISlide) {
    const updatedSlider: ISlider = {
      ...slider,
      items: [...slider.items],
    };

    const currentSlide = updatedSlider.items.find(el => el.id === slide.id);
    const slideIndex = updatedSlider.items.indexOf(currentSlide);
    if (slideIndex === -1) return;
    updatedSlider.items.splice(slideIndex, 1, slide);

    dispatch(
      sliderUpsert({
        slider: updatedSlider,
        slideUpdateData: { slideId: slide.id, file: image.file },
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(updateSlider(updatedSlider));
        onClose();
      })
      .catch(e => console.error(e));
  }

  const onChangeFunction = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { inputType } = event.nativeEvent as InputEvent;
    if (inputType === 'insertFromPaste') {
      const newVal = correctUrlOnPaste(event)
      setValue('clickUrl', newVal);
    }else {
      setValue('clickUrl', trimAndReplaceFunction(event.target.value));
    }
  }

  return (
    <Modal
      name={ModalNames.SettingStory}
      active={active}
      propsStyles={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
      onClose={onClose}
    >
      <div ref={modalHeaderRef} className={css.header}>
        <Text text={t('modalSettingSlider.slide')} mod="title" />
      </div>
      <div className={css.modalInner} ref={modalInnerRef}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={css.wrapper}>
            <ImageLoader
              name="slide-image"
              propsStyles={{ width: 160, height: 160, borderRadius: '8px' }}
              message={t('modalSettingSlider.minimumResolutionIs', minimumResolution)}
              onChangeFile={onChangeFile}
              imageUrl={image.url}
            />
          </div>
          <div className={css.wrapper}>
            <Input
              controlled={false}
              {...register('clickUrl', {
                required: t('modalSettingSlider.requiredField'),
                validate: validateUrl,
                maxLength: {
                  value: linkMaxLength,
                  message: tPlural('modalSettingSlider.linkShouldBeLessThanCharacters', linkMaxLength)
                },
              })}
              type="text"
              label={t('modalSettingSlider.link')}
              placeholder={t('modalSettingSlider.opensWhenTheSlideIsClicked')}
              propsStyles={{ backgroundColor: theme.background }}
              maxLength={1000}
              errorMessage={errors.clickUrl?.message}
              message={
                <Trans
                  text={t(
                    'modalSettingSlider.weRecommendToUseTeletypeSoUsersDontLeaveYourStore',
                    '<0>',
                    '</0>',
                  )}
                  components={[
                    <a key={1} href="https://teletype.in/" target="_blank" rel="noreferrer" />,
                  ]}
                />
              }
              onChange={onChangeFunction}
            />
          </div>
          {editingSlide && (
            <div className={css.removeBtnWrapper}>
              <Button
                text={t('modalSettingSlider.remove')}
                propsStyles={{ background: colorGrey, color: colorBlack, width: '100%' }}
                hasGradient={false}
                onClick={clickRemoveButton}
              />
            </div>
          )}

          <div className={css.footer} ref={modalFooterRef}>
            <Button
              disabled={!image.url || networkStatus === NetworkStatus.Loading ? true : false}
              text={
                editingSlide
                  ? t('modalSettingSlider.saveSlide')
                  : t('modalSettingSlider.createSlide')
              }
              propsStyles={{ width: '100%' }}
              type="submit"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
});
